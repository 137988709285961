import React from "react"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import will from "../images/will.png"

console.log(will)

const duration = 0.7

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.08,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className="container"
      >
        <motion.div className="content" variants={item} transition="easeInOut">
          <h1 className="text-jumbo lust-italic text-primary my-16 pb-10 font-normal">
            <i className="first-h">P</i>roduct design, leadership, visuals,
            systems thinking, brand, prototypes.
          </h1>
        </motion.div>
      </motion.section>

      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className="container flex"
      >
        <motion.div
          className="content flex flex-row content-start"
          variants={item}
          transition="easeInOut"
        >
          <img src={will} className="h-16 w-16 rounded-full mr-4"></img>
        </motion.div>
        <motion.div
          className="content flex flex-col content-start"
          variants={item}
          transition="easeInOut"
        >
          <h5 className="text-primary mb-1">Will Smith</h5>
          <small className="text-secondary">Boston, Massachusetts</small>
        </motion.div>
      </motion.section>
    </>
  )
}

export default IndexPage
